<template>
<div class="row tgju-widgets-row">
    <slot v-if="widgetLoad">
        <div class="text-center p-2">
            <div class="widget-loading"></div>
        </div>
    </slot>
    <slot v-else>
        <div class="tgju-widgets-block col-12 col-md-12 col-lg-12  profile-performance">
            <div class="tgju-widget light has-icon">

                <div v-if="cryptoembed == null" class="tgju-widget-title dot m-0">
                    <h2>عملکرد {{ market_performance.info.title }}</h2>
                    <span class="tgju-widget-up-i">{{ market_performance.info.time }} <i class="fa fa-clock-o" aria-hidden="true"></i></span>
                </div>

                <div id="stocks-table-data" class="tables-data table-mobile-grid">
                    <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper no-footer">
                        <table class="table widgets-dataTable table-hover text-center performance-table dataTable no-footer">
                            <thead class="text-center">
                                <tr>
                                    <th data-target="profile-tour-step-11">نام</th>
                                    <th data-target="profile-tour-step-12">روز</th>
                                    <th data-target="profile-tour-step-13">یک هفته</th>
                                    <th data-target="profile-tour-step-14">یک ماه</th>
                                    <th data-target="profile-tour-step-15">شش ماه</th>
                                    <th data-target="profile-tour-step-16">یک سال</th>
                                    <th data-target="profile-tour-step-17">سه سال</th>
                                </tr>
                            </thead>
                            <tbody id="table-list">
                                <tr v-for="(item, index) in category_performance" :key="index">
                                    <td data-target="performance-mobile-tour-step-1" class=""><a :href="`profile/${ item.item_id }`">{{ item.title }}</a></td>
                                    <td data-target="performance-mobile-tour-step-2"><span :class="`${ item.dt }`" dir="ltr">{{ item.d != "0" ? formatPrice(item.d) : '-' }}</span></td>
                                    <td data-target="performance-mobile-tour-step-3"><span :class="`${ item['1_week'].dt }`" dir="ltr">{{ item['1_week'].d ? formatPrice(item['1_week'].d) : '-' }}</span></td>
                                    <td data-target="performance-mobile-tour-step-4"><span :class="`${ item['1_month'].dt }`" dir="ltr">{{ item['1_month'].d ? formatPrice(item['1_month'].d) : '-' }}</span></td>
                                    <td data-target="performance-mobile-tour-step-5"><span :class="`${ item['6_months'].dt }`" dir="ltr">{{ item['6_months'].d ? formatPrice(item['6_months'].d) : '-' }}</span></td>
                                    <td data-target="performance-mobile-tour-step-6"><span :class="`${ item['1_year'].dt }`" dir="ltr">{{ item['1_year'].d ? formatPrice(item['1_year'].d) : '-' }}</span></td>
                                    <td data-target="performance-mobile-tour-step-7"><span :class="`${ item['3_years'].dt }`" dir="ltr">{{ item['3_years'].d ? formatPrice(item['3_years'].d) : '-' }}</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                
            </div>
        </div>

        <div class="tgju-widgets-block col-12 col-md-12 col-lg-6  profile-performance">
            <div class="tgju-widget light has-icon">
                <h4 class="tgju-widget-title dot m-0">  نمودار عملکرد ۱۵ روزه شاخص</h4>

                <table class="table widgets-dataTable">
                    <tbody class="text-center">
                        <tr>
                            <td class="text-tbl-center text-right td-dot"><h5 class="performance-h4">میانگین شاخص در بازه ۱۵ روزه: <span dir="ltr">{{ market_performance.chart_detail['15_days'][0] }}</span> {{ market_performance.info.currency_unit }}</h5></td>
                        </tr>
                        <tr>
                            <td class="text-tbl-center text-right td-dot"><h5 class="performance-h4">اختلاف روز جاری با میانگین ۱۵ روزه: <span dir="ltr" v-html="market_performance.chart_detail['15_days'][1]"></span></h5></td>
                        </tr>
                        <tr>
                            <td class="text-tbl-center text-right td-dot"><h5 class="performance-h4">درصد اختلاف شاخص در روز جاری با میانگین ۱۵ روزه: <span dir="ltr" v-html="market_performance.chart_detail['15_days'][2]"></span></h5></td>
                        </tr>
                    </tbody>
                </table>
                <HighCharts :options="chart_15_options" v-if="chart_15_options"></HighCharts>
                <!-- <div id="chart-15" style="width: 100%; height: 340px; margin: 0 auto;margin-top: -16px;"></div> -->
            </div>
        </div>

        <div class="tgju-widgets-block col-12 col-md-12 col-lg-6  profile-performance">
            <div class="tgju-widget light has-icon">
                <h4 class="tgju-widget-title dot m-0"> نمودار عملکرد ۱ ماهه شاخص</h4>

                <table class="table widgets-dataTable">
                    <tbody class="text-center">
                        <tr>
                            <td class="text-tbl-center text-right td-dot"><h5 class="performance-h4">میانگین شاخص در بازه ۱ ماهه: <span dir="ltr">{{ market_performance.chart_detail['1_month'][0] }}</span> {{ market_performance.info.currency_unit }}</h5></td>
                        </tr>
                        <tr>
                            <td class="text-tbl-center text-right td-dot"><h5 class="performance-h4">اختلاف روز جاری با میانگین ۱ ماهه: <span dir="ltr" v-html="market_performance.chart_detail['1_month'][1]"></span></h5></td>
                        </tr>
                        <tr>
                            <td class="text-tbl-center text-right td-dot"><h5 class="performance-h4">درصد اختلاف روز جاری با میانگین ۱ ماهه: <span dir="ltr" v-html="market_performance.chart_detail['1_month'][2]"></span></h5></td>
                        </tr>
                    </tbody>
                </table>
                <HighCharts :options="chart_30_options" v-if="chart_30_options"></HighCharts>
                <!-- <div id="chart-30" style="width: 100%; height: 340px; margin: 0 auto;margin-top: -16px;"></div> -->
            </div>
        </div>

        <div class="tgju-widgets-block col-12 col-md-12 col-lg-6  profile-performance">
            <div class="tgju-widget light has-icon">
                <h4 class="tgju-widget-title dot m-0"> نمودار عملکرد ۳ ماهه شاخص</h4>

                <table class="table widgets-dataTable">
                    <tbody class="text-center">
                        <tr>
                            <td class="text-tbl-center text-right td-dot"><h5 class="performance-h4">میانگین شاخص در بازه ۳ ماهه: <span dir="ltr">{{ market_performance.chart_detail['3_months'][0] }}</span> {{ market_performance.info.currency_unit }}</h5></td>
                        </tr>
                        <tr>
                            <td class="text-tbl-center text-right td-dot"><h5 class="performance-h4">اختلاف روز جاری با میانگین ۳ ماهه: <span dir="ltr" v-html="market_performance.chart_detail['3_months'][1]"></span></h5></td>
                        </tr>
                        <tr>
                            <td class="text-tbl-center text-right td-dot"><h5 class="performance-h4">درصد اختلاف روز جاری با میانگین ۳ ماهه": <span dir="ltr" v-html="market_performance.chart_detail['3_months'][2]"></span></h5></td>
                        </tr>
                    </tbody>
                </table>
                <HighCharts :options="chart_3_options" v-if="chart_3_options"></HighCharts>
                <!-- <div id="chart-3" style="width: 100%; height: 340px; margin: 0 auto;margin-top: -16px;"></div> -->
            </div>
        </div>

        <div class="tgju-widgets-block col-12 col-md-12 col-lg-6 profile-performance">
            <div class="tgju-widget light has-icon">
                <h4 class="tgju-widget-title dot m-0"> نمودار عملکرد ۶ ماهه شاخص</h4>

                <table class="table widgets-dataTable">
                    <tbody class="text-center">
                        <tr>
                            <td class="text-tbl-center text-right td-dot"><h5 class="performance-h4">میانگین شاخص در بازه ۶ ماهه: <span dir="ltr">{{ market_performance.chart_detail['6_months'][0] }}</span> {{ market_performance.info.currency_unit }}</h5></td>
                        </tr>
                        <tr>
                            <td class="text-tbl-center text-right td-dot"><h5 class="performance-h4">اختلاف روز جاری با میانگین ۶ ماهه: <span dir="ltr" v-html="market_performance.chart_detail['6_months'][1]"></span></h5></td>
                        </tr>
                        <tr>
                            <td class="text-tbl-center text-right td-dot"><h5 class="performance-h4">درصد اختلاف روز جاری با میانگین ۶ ماهه: <span dir="ltr" v-html="market_performance.chart_detail['6_months'][2]"></span></h5></td>
                        </tr>
                    </tbody>
                </table>
                <HighCharts :options="chart_6_options" v-if="chart_6_options"></HighCharts>
                <!-- <div id="chart-6" style="width: 100%; height: 340px; margin: 0 auto;margin-top: -16px;"></div> -->
            </div>
        </div>
    </slot>
</div>
</template>

<script>
// این کامپوننت دارای اجزای ویجت نمودار ها در ویجت ساز داشبورد می باشد .
import HighCharts from '@/plugins/HighCharts.vue'
export default {
    name: "MarketPerformanceComponent",
    components: {
        HighCharts,
    },
    data() {
        return {
            widgetLoad: true,
            market_performance: [],
            category_performance: [],
            cryptoembed: null,
            chart_15_options: null,
            chart_30_options: null,
            chart_3_options: null,
            chart_6_options: null,
        }
    },
    mounted() {
        this.getProfileHistory();
    },
    methods: {
        getProfileHistory() {
            this.$helpers
                .makeRequest("GET", `/market/profile-performance/${this.$route.params.name}`)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.market_performance = api_response.data.response.detail.response;
                        this.$parent.title = this.market_performance.info.title + ' - ' + this.$route.meta.title;
                        this.category_performance = this.market_performance.category_performance;
                        this.widgetLoad = false;

                        this.chart_15_options = {
                            // TODO: ممکنه chartData مقدار نداشته باشه
                            chartData: JSON.parse(this.market_performance.charts['15_days']),
                            tooltipTitle: 'قیمت امروز',
                            chartType: "areaspline",
                            buttonEnabled: false,
                            markerEnabled: false,
                            gridLineStyle: "longdash",
                            fillOpacity: 0.1,
                            markerLineWidth: 1,
                            yAxisLineColor: "#feeeec",
                            backgroundColor: false,
                            plotColor: "#892731",
                            markerFillColor: "#feeeec",
                            plotShadow: false,
                            tooltipOnlyTime: false,
                            navigatorEnabled: true
                        };

                        this.chart_30_options = {
                            // TODO: ممکنه chartData مقدار نداشته باشه
                            chartData: JSON.parse(this.market_performance.charts['1_month']),
                            tooltipTitle: 'قیمت امروز',
                            chartType: "areaspline",
                            buttonEnabled: false,
                            markerEnabled: false,
                            gridLineStyle: "longdash",
                            fillOpacity: 0.1,
                            markerLineWidth: 1,
                            yAxisLineColor: "#feeeec",
                            backgroundColor: false,
                            plotColor: "#892731",
                            markerFillColor: "#feeeec",
                            plotShadow: false,
                            tooltipOnlyTime: false,
                            navigatorEnabled: true
                        };

                        this.chart_3_options = {
                            // TODO: ممکنه chartData مقدار نداشته باشه
                            chartData: JSON.parse(this.market_performance.charts['3_months']),
                            tooltipTitle: 'قیمت امروز',
                            chartType: "areaspline",
                            buttonEnabled: false,
                            markerEnabled: false,
                            gridLineStyle: "longdash",
                            fillOpacity: 0.1,
                            markerLineWidth: 1,
                            yAxisLineColor: "#feeeec",
                            backgroundColor: false,
                            plotColor: "#892731",
                            markerFillColor: "#feeeec",
                            plotShadow: false,
                            tooltipOnlyTime: false,
                            navigatorEnabled: true
                        };

                        this.chart_6_options = {
                            // TODO: ممکنه chartData مقدار نداشته باشه
                            chartData: JSON.parse(this.market_performance.charts['6_months']),
                            tooltipTitle: 'قیمت امروز',
                            chartType: "areaspline",
                            buttonEnabled: false,
                            markerEnabled: false,
                            gridLineStyle: "longdash",
                            fillOpacity: 0.1,
                            markerLineWidth: 1,
                            yAxisLineColor: "#feeeec",
                            backgroundColor: false,
                            plotColor: "#892731",
                            markerFillColor: "#feeeec",
                            plotShadow: false,
                            tooltipOnlyTime: false,
                            navigatorEnabled: true
                        };
                    }
                });
        },
        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            value = parseFloat(value);
            return parseFloat(value.toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    },
}
</script>